import { useAppContext } from '@ftdr/blueprint-components-react'
import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

export interface AddedShoppingCartItem {
  sku?: string
  vendor?: string
  unitPrice?: number
  isTax?: boolean
  unitTax?: number
  category?: string
  subcategory?: string
  description?: string
  overrideReason?: string
  originalPrice?: number
  isAvailableForZip?: boolean
}

export type TShouldConfirm = {
  sku: string
  q: number
} | null
interface IShoppingCartItem {
  sku?: string
  vendor?: string
  quantity?: number
  category?: string
  subcategory?: string
  description?: string
  isAvailableForZip?: boolean
  removalReason?: string
}
interface IButton {
  label: string
  currentID?: string
}
interface IInputData {
  sku?: string
}
export interface IShoppingCartData {
  item: IShoppingCartItem
  price: {
    originalPrice?: number
    unitPrice?: number
    overrideReason?: string
  }

  quantity?: number
  unitTax?: number
  total?: number
  button?: IButton
  inputData?: IInputData
}
interface Props {
  shoppingCartData: IShoppingCartData[]
  total: number
  hasNegativeValue: boolean
  setHasNegativeValue: Dispatch<boolean>
  tax: appliancepb.ITaxDetail
  setTax: Dispatch<appliancepb.ITaxDetail>
  setShoppingCartData: (
    data: IShoppingCartData[],
    tax?: appliancepb.ITaxDetail
  ) => void
  resetShoppingCartData: () => void
  currentItem: IShoppingCartData
  setCurrentItem: (id: string) => void
  updateCurrentItemQuantity: (
    id: string,
    quantity: number,
    removalReason?: string
  ) => void
  addItemToShoppingCart: (item: AddedShoppingCartItem) => void
  shouldConfirm: TShouldConfirm
  setShouldConfirm: Dispatch<TShouldConfirm>
  isShoppingCartEmpty: boolean
  vendorServiceCode: string | null
  setVendorServiceCode: Dispatch<string | null>
  reapplyCil: (id: string) => void
  hasCartUnavailableItems: boolean
  verifyCartAvailability: () => boolean
  existInCart: (sku: string) => boolean
  lastSku: string | null
  setLastSku: Dispatch<string | null>
  cilValue: number
}
type TShoppingCartContext = {
  children?: React.ReactNode
}
const ShoppingCartContext = createContext<Props>({} as Props)

const ShoppingCartProvider: React.FC<TShoppingCartContext> = ({ children }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [shouldConfirm, setShouldConfirm] = useState<TShouldConfirm>(null)
  const [total, setTotal] = useState<number>(0)
  const [tax, setTax] = useState<appliancepb.ITaxDetail>({})
  const [shoppingCartData, setShoppingCartData] = useState<IShoppingCartData[]>(
    []
  )
  const [currentItem, setCurrentItem] = useState<IShoppingCartData>(
    {} as IShoppingCartData
  )
  const [hasNegativeValue, setHasNegativeValue] = useState<boolean>(false)
  const [isShoppingCartEmpty, setIsShoppingCartEmpty] = useState<boolean>(false)
  const [vendorServiceCode, setVendorServiceCode] = useState<string | null>(
    null
  )
  const [hasCartUnavailableItems, setHasCartUnavailableItems] = useState<
    boolean
  >(false)
  const [lastSku, setLastSku] = useState<string | null>(null)
  const [cilValue, setCilValue] = useState<number>(0)

  const sumAll = (data: IShoppingCartData[]) =>
    data.reduce(
      (a, b) =>
        b.item.category !== 'CIL' ? a + (b.total || 0) : a - (b.total || 0),
      0
    )
  const setShoppingCart = (
    data: IShoppingCartData[],
    tax?: appliancepb.ITaxDetail
  ) => {
    tax && setTax(tax)
    setShoppingCartData(data)
    const t = sumAll(data)
    setTotal(t)
  }
  const resetShoppingCartData = () => {
    setLastSku('')
    setTax({})
    setShoppingCartData([])
  }
  const setCurrentItemHandler = (id: string) => {
    const item = shoppingCartData.find((item) => item.item.sku === id)
    if (!item) return
    setCurrentItem(item)
  }
  const existInCart = (sku: string) =>
    !!shoppingCartData.find((elem) => elem.item.sku === sku)

  let temp = useRef<IShoppingCartData[]>([])

  const addItemToShoppingCart = (item: AddedShoppingCartItem) => {
    const {
      sku,
      vendor,
      category,
      subcategory,
      description,
      unitPrice,
      unitTax,
      originalPrice,
      overrideReason,
      isAvailableForZip,
    } = item
    const exist = existInCart(sku || '')

    const itemTax = unitTax || 0

    const price = {
      originalPrice: originalPrice || unitPrice,
      overrideReason,
      unitPrice,
    }

    if (!exist) {
      const newItem = {
        item: {
          sku,
          vendor,
          quantity: 1,
          category,
          subcategory,
          description,
          isAvailableForZip,
        },
        quantity: 1,

        price,

        unitTax: itemTax,
        total: +(itemTax + Number(unitPrice)).toFixed(2),

        button: {
          label:
            /^AHS-CIL$/.test(sku || '') || /^HSA-CIL$/.test(sku || '')
              ? localizedText('SHOPPING_CART_BUTTON_REAPPLY')
              : localizedText('SHOPPING_CART_BUTTON_ADJUST'),
          currentID: sku,
        },
        inputData: { quantity: 1, sku },
      }
      temp.current = [...temp.current, newItem]
    } else {
      temp.current = [...temp.current].map((elem) =>
        elem.item.sku === sku
          ? {
              ...elem,
              item: { ...elem.item, quantity: Number(elem.quantity) + 1 },
              quantity: Number(elem.quantity) + 1,
              inputData: {
                ...elem.inputData,
                quantity: Number(elem.quantity) + 1,
              },
              total: Number(
                (
                  (Number(elem.price.unitPrice) + Number(elem.unitTax)) *
                  (Number(elem.quantity) + 1)
                ).toFixed(2)
              ),
              button: {
                label:
                  Number(unitPrice) >= 0
                    ? localizedText('SHOPPING_CART_BUTTON_ADJUST')
                    : localizedText('SHOPPING_CART_BUTTON_REAPPLY'),
                currentID: sku,
              },
            }
          : elem
      )
    }
    setShoppingCartData(temp.current)
    const t = sumAll(temp.current)
    setTotal(t)
    setLastSku(temp.current[temp.current.length - 1]?.item?.sku || null)
    console.log('CARD', temp.current)
  }
  const updateCurrentItemQuantity = (
    id: string,
    quantity: number,
    removalReason: string = ''
  ) => {
    const items = shoppingCartData.map((item) =>
      item.item.sku === id
        ? {
            ...item,
            item: { ...item.item, quantity, removalReason },
            quantity,
            inputData: { ...item.inputData, quantity },
            total: Number(
              (
                (Number(item.price.unitPrice) + Number(item.unitTax || 0)) *
                Number(quantity)
              ).toFixed(2)
            ),
          }
        : item
    )
    if (!items) return
    temp.current = items
    setShoppingCartData(items)
    const t = sumAll(items)
    const q = temp.current.reduce((a, b) => a + (b.quantity || 0), 0)
    setTotal(t)
    q === 0 && items.length > 0 && setShoppingCartData([])
  }
  const reapplyCil = (id: string) => {
    const total = sumAll(temp.current)
    temp.current = [...temp.current].map((item) => {
      return item.item.sku === id
        ? {
            ...item,
            total: Number(
              (
                (Number(item.price.unitPrice! - total) +
                  Number(item.unitTax || 0)) *
                Number(item.quantity)
              ).toFixed(2)
            ),

            price: {
              ...item.price,
              unitPrice: item.price.unitPrice! - total,
              overrideReason: 'Reapply',
            },
          }
        : item
    })
    setShoppingCartData(temp.current)

    setTotal(sumAll(temp.current))
  }
  const verifyCartAvailability = () => {
    if (
      !!shoppingCartData.find((elem) => {
        return (
          elem.item.category !== 'SERVICE CODE' && !elem.item.isAvailableForZip
        )
      })
    ) {
      setHasCartUnavailableItems(true)
      return false
    } else {
      setHasCartUnavailableItems(false)
      return true
    }
  }

  useEffect(() => {
    temp.current = shoppingCartData
    const t = sumAll(shoppingCartData)
    setTotal(t)
    const cilElem = shoppingCartData.find((v) => /-CIL$/.test(v.item.sku || ''))
    cilElem && setCilValue(cilElem.price.originalPrice || 0)
  }, [shoppingCartData])

  useEffect(() => {
    shoppingCartData.length > 0
      ? setIsShoppingCartEmpty(false)
      : setIsShoppingCartEmpty(true)
    const serviceVendorElelemnt = shoppingCartData.find(
      (v) => v.item.vendor === 'AHS' || v.item.vendor === 'HSA'
    )
    serviceVendorElelemnt &&
      setVendorServiceCode(serviceVendorElelemnt.item.vendor || null)
    verifyCartAvailability()
    //eslint-disable-next-line
  }, [shoppingCartData.length])

  useEffect(() => {
    shoppingCartData.length > 0
      ? setIsShoppingCartEmpty(false)
      : setIsShoppingCartEmpty(true)
    const serviceVendorElelemnt = shoppingCartData.find(
      (v) => v.item.vendor === 'AHS' || v.item.vendor === 'HSA'
    )
    serviceVendorElelemnt &&
      setVendorServiceCode(serviceVendorElelemnt.item.vendor || null)

    //eslint-disable-next-line
  }, [shoppingCartData.length])

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCartData,
        total,
        hasNegativeValue,
        setHasNegativeValue,
        tax,
        setTax,
        setShoppingCartData: setShoppingCart,
        currentItem,
        setCurrentItem: setCurrentItemHandler,
        updateCurrentItemQuantity,
        addItemToShoppingCart,
        shouldConfirm,
        setShouldConfirm,
        resetShoppingCartData,
        isShoppingCartEmpty,
        vendorServiceCode,
        setVendorServiceCode,
        reapplyCil,
        hasCartUnavailableItems,
        verifyCartAvailability,
        existInCart,
        lastSku,
        setLastSku,
        cilValue,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

const useShoppingCartContext = () => useContext(ShoppingCartContext)

export { ShoppingCartProvider, useShoppingCartContext }
