import React from 'react'
import { Input, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { useForm, Controller } from 'react-hook-form'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { yupResolver } from '@hookform/resolvers/yup'
import { addCilFormSchema } from './validation'

const initValues = {
  price: '',
}
interface IProps {
  onClose: () => void
  propTenant: string
}
interface IForm {
  price: string
}
const AddFeeForm: React.FC<IProps> = ({ onClose, propTenant }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { addItemToShoppingCart } = useShoppingCartContext()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(addCilFormSchema),
    defaultValues: initValues,
  })
  const submitForm = (data: IForm) => {
    addItemToShoppingCart({
      sku: `${propTenant}-CIL`,
      vendor: propTenant,
      unitPrice: Number(data.price) * 100,
      isTax: false,
      category: 'CIL',
      subcategory: 'Cash In Lie Value',
      description: 'Cash In Lie Value',
      isAvailableForZip: true,
    })

    onClose()
  }

  return (
    <div className="max-w-md">
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-price"
            error={errors.price?.message}
            inputMode="decimal"
            startEnhancer={() => <Text>$</Text>}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_PRICE')}
            className="w-full"
            onChange={(e) => {
              const val = e.target.value
              const step1Regex = /^\d+\.?$/
              const step2Regex = /^\d+(\.\d{1,2})?$/

              if (val === '' || step1Regex.test(val) || step2Regex.test(val)) {
                field.onChange(e)
              }
            }}
            onBlur={(e) => {
              e.target.value = Number(e.target.value).toFixed(2)
              field.onChange(e)
              field.onBlur()
            }}
          />
        )}
      />

      <div className="flex mt-8">
        <Button
          label={localizedText('BUTTON_ADD_TO_CART')}
          size="medium"
          onClick={() => {
            handleSubmit(submitForm)()
          }}
        />
        <Button
          className="ml-6"
          label={localizedText('BUTTON_CANCEL')}
          size="medium"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default AddFeeForm
