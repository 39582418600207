import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import React, { useState } from 'react'

import AddCilForm from '../add-cil-form/add-cil-form'
import { MenuItem } from 'src/utils/shared-types'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'

interface IProps {
  propTenant: MenuItem
  vendor: string
}
const AddCil: React.FC<IProps> = ({ propTenant: { id }, vendor }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { cilValue } = useShoppingCartContext()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        id="add-cil-button"
        label={localizedText('BUTTON_ADD_CIL')}
        className="custom-button w-full md:w-auto flex justify-center lg:inline-block h-9"
        disabled={!id || !vendor || !!cilValue}
        onClick={() => setIsModalOpen(true)}
        style={{ lineHeight: 1 }}
      />

      <Dialog
        id="add-fee-dialog"
        open={isModalOpen}
        modal={true}
        onClose={() => setIsModalOpen(false)}
        className=""
        usePortal={true}
      >
        <AddCilForm propTenant={id} onClose={() => setIsModalOpen(false)} />
      </Dialog>
    </>
  )
}

export default AddCil
